@import "../styles/variables.scss";

.msgBox {
  display: block;
  border-radius: 5px;
  border: 1px solid $line;
  margin: 25px 0;
  overflow: auto;
  width: 100%;
}

.msgWrapper {
  line-height: 1.5;
  margin: 20px 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  @media (max-width: $breakpoint) {
    display: flex;
    flex-direction: column;
  }
  .key {
    display: table-cell;
  }
  .key:first-child {
    width: 200px;
    font-weight: 500;
    font-size: 14px;
    padding-right: 20px;
  }
}

.type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 25px 30px;
  line-height: 1;
  background-color: rgba(84, 147, 247, 0.05);
}

.events {
  margin-bottom: 20px;
  @media (max-width: $breakpoint) {
    tr,
    th,
    td {
      display: block;
    }
    tr {
      margin-bottom: 10px;
    }
  }
}

.eventType {
  font-weight: normal;
  font-size: 16px;
  @media (max-width: $breakpoint) {
    margin-top: 20px;
    font-weight: 500;
  }
}

.attrKey {
  width: 155px;
  font-weight: 500;
}

.attrValue {
  white-space: pre-line;
}

.action {
  display: flex;
  flex-direction: column;
}

.details {
  padding: 20px 30px 0 30px;
}

.msgType {
  font-size: 16px;
  font-weight: 500;
}

.show {
  border-bottom: 1px solid $line;
}

.button {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: #5493f7;
  padding: 20px 0;
}
