@import "../styles/variables.scss";

.select {
  width: 100px;
  height: 36px;
  padding: 0 41px 0 10px !important;
  border-radius: 3px !important;
  border: 1px solid hsla(0, 0%, 100%, 0.3) !important;
  font-size: 13px !important;
  color: #fff !important;
  font-weight: 300 !important;
  letter-spacing: -0.2px;
  text-overflow: ellipsis;
  background: transparent;
  appearance: none;
  text-transform: capitalize;
  option {
    color: $brand;
    background: $line;
    text-transform: capitalize;
  }
  @media (max-width: $breakpoint) {
    width: 80px;
    height: 28px !important;
    font-size: 11px !important;
  }
}

.addon {
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
  display: block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 34px;
  border-left: 1px solid hsla(0, 0%, 100%, 0.3) !important;
  background: transparent;
  text-align: center;
  color: #fff !important;
  line-height: 34px;
  pointer-events: none;
  @media (max-width: $breakpoint) {
    width: 27px !important;
  }
  i {
    font-size: 18px;
    vertical-align: middle;
    margin-top: -2px;
    @media (max-width: $breakpoint) {
      height: 24px;
    }
  }
}
