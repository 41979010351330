@import "../styles/variables.scss";

.header {
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(23, 40, 81, 1) 0%,
    rgba(26, 26, 27, 1) 100%
  );
  height: 80px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  @media (max-width: $breakpoint) {
    height: 44px;
  }
}

.inner {
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
  position: relative;
}

.subtitle {
  padding-left: 2.55rem;
  margin-top: -0.2rem;
  font-size: 0.8rem;
  color: #fff;
  @media (max-width: $breakpoint) {
    font-size: 0.6rem;
    padding-left: 1.45rem;
    margin-top: 0.1rem;
  }
}

.logo {
  display: inline-block;
  @media (max-width: $breakpoint) {
    height: 45px;
    line-height: 45px;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  a {
    display: block;
    padding: 18px 40px;
    line-height: 1;
    @media (max-width: $breakpoint) {
      padding: 13px 15px;
    }
  }
  img {
    width: 220px;
    height: 41px;
    @media (max-width: $breakpoint) {
      height: 15px;
      width: 116px;
    }
  }
}

.search {
  position: absolute;
  top: 20px;
  right: 330px;
  width: 34%;
  max-width: 640px;
  margin-left: -320px;
  @media (max-width: $breakpoint) {
    top: 54px;
    max-width: none;
    right: 0;
    width: 100%;
  }
}
