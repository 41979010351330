@import "../../styles/mixins";
@import "../../styles/variables";

.component {
  border: 1px solid $line;
  border-radius: 3px;
  box-shadow: 0 0 10px 0 rgba(black, 0.08);
  margin-bottom: 20px;
  padding: 40px;
  text-align: center;
}

.header {
  margin: 10px 0 40px;
}

.title {
  font-size: 20px;
  font-weight: bold;
}

.timestamp {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}

.processing {
  @include flex;
  font-size: 16px;
  text-transform: uppercase;
  white-space: nowrap;
  .item {
    margin-left: 5px;
  }
}

.icon {
  &:nth-child(2) {
    opacity: 0.66;
  }

  &:nth-child(3) {
    opacity: 0.33;
  }
}

.desc {
  font-size: 14px;
}
