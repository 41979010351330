/* colors */
.text-primary {
  color: $brand;
}

.text-secondary {
  color: $sky;
}

.text-success {
  color: $green;
}

.text-warning {
  color: $orange;
}

.text-danger {
  color: $red;
}

/* alignments */
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
