.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-radius: 22px;
  font-size: 15px;
  height: 44px;
  min-width: 180px;
  padding: 0 30px;

  & + & {
    margin-left: 10px;
  }
}

a.btn {
  text-decoration: none;
}

.btn-block {
  width: 100%;
}

.btn-sm {
  border-radius: 15px;
  font-size: 14px;
  height: 30px;
  min-width: 100px;
  padding: 0 15px;
}

/* Colors */
.btn-primary {
  background: $brand;
  color: white;

  &:hover:not(:disabled) {
    background: darken($brand, 5%);
  }

  &:disabled {
    opacity: 0.5;
  }
}

.btn-sky {
  background: $sky;
  color: white;

  &:hover:not(:disabled) {
    background: darken($sky, 5%);
  }

  &:disabled {
    opacity: 0.5;
  }
}

.btn-danger {
  background: $red;
  color: white;

  &:hover:not(:disabled) {
    background: darken($red, 5%);
  }

  &:disabled {
    opacity: 0.5;
  }
}

.btn-light {
  background: $line;
  color: $brand;

  &:hover:not(:disabled) {
    background: darken($line, 5%);
  }

  &:disabled {
    opacity: 0.5;
  }
}

.btn-link {
  color: $sky;
  text-decoration: underline;
}

.btn-icon-group {
  @include flex();
  margin: -10px;
}

.btn-icon {
  @include flex();

  border-radius: 5px;
  padding: 10px;

  &:hover {
    background: rgba(32, 67, 181, 0.05);
  }
}
