@import "grid";
@import "tables";
@import "buttons";
@import "forms";
@import "card";
@import "badge";
@import "text";

/* flex */
.flex {
  @include flex(flex-start);
}

.space-between {
  justify-content: space-between;
}

/* utils */
.clickable {
  cursor: pointer;
  color: $sky;
  white-space: nowrap;
}

.mobile {
  @media (min-width: $breakpoint) {
    display: none !important;
  }
}

.desktop {
  @media (max-width: $breakpoint - 1) {
    display: none !important;
  }
}

.desktop-large {
  @media (max-width: $breakpoint-large - 1) {
    display: none !important;
  }
}

/* border */
.border {
  $border: 1px solid $line;
  border: $border;

  &-top {
    border-top: $border;
  }

  &-bottom {
    border-bottom: $border;
  }

  &-left {
    border-left: $border;
  }

  &-right {
    border-right: $border;
  }
}
