*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  line-height: 1.5;
  margin: 0;
  font-family: Gotham, Helvetica Neue, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: -0.2px;
  -webkit-tap-highlight-color: transparent;
  word-break: break-word;
  background-color: #162852;
}

hr {
  margin-top: $gutter;
  margin-bottom: $gutter;
  border: 0;
  border-top: 1px solid $line;
}

a {
  color: $link-bg;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

strong,
.strong {
  font-weight: 500;
}

h1,
h2 {
  font-size: inherit;
  font-weight: 500;
}

h1,
h2,
p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

table {
  width: 100%;
}

th {
  text-align: left;
}

button,
input,
textarea,
select {
  border: 0;
  outline: 0;
  padding: 0;
  background: transparent;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button:not(:disabled),
select:not(:disabled) {
  cursor: pointer;
}

input,
textarea {
  resize: none;

  &::placeholder {
    color: fade-out($brand, 0.7);
  }

  &:read-only {
    border-color: $line;
    background: $line;
  }
}

small {
  font-size: 75%;
}

select::-ms-value {
  background: transparent;
}
select::-ms-expand {
  display: none;
  color: #000;
}
