@import "../styles/mixins";
@import "../styles/variables";

.header {
  @include flex(space-between);
  margin-bottom: 20px;
}

.title {
  color: #ffd83d;
  font-size: 26px;
}

.article > h2 {
  color: #ffd83d;
  font-size: 20px;
  margin: 30px 0 15px;
}
